import React from "react";
import "../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container} from "react-bootstrap";

function Footer() {

  return (
    <Container fluid className="footer">
      
    </Container>
  );
}

export default Footer;
