import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/home-main.svg";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Particle from "../ParticleTS";
import Tilt from "react-parallax-tilt";
import FindMeOn from "./FindMeOn";
import Gallery from "../Gallery";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              A little bit about <span className="purple">me</span>.
            </h1>
            <p className="home-about-body">
            I'm a Computer Science undergraduate student at UoW with a passion for DevOps, Cloud Computing and Coding (💻 It's like a chess game, with bugs as opponents and debugging as my winning move !).
              <br />
              <br />I'm a DevOps enthusiast with several months of experience as a DevOps Engineering Intern and I've got some serious skills under my belt! I've delved deep into the DevOps world.
              <br />
              <br />
              What really gets me fired up is using my skills and experience to help others grow in the field. That's why I'm so passionate about mentoring and sharing my knowledge with others. Also, as a video game player, I've learned that my best ideas for engineering solutions often come right after an epic boss battle! 🎮
              &nbsp;



            </p>
          </Col>

        </Row>

      </Container>

      <Container >
        <Container fluid className="about-section">
          <Row>
            <Col md={12} className="home-about-social">
              <FindMeOn />
            </Col>
          </Row>
          <Gallery />
        </Container>

      </Container>
      <Particle />
    </Container>

  );
}
export default Home2;
